<template>
  <div class="app-container app-theme-white body-tabs-shadow">
    <div class="app-container">
      <div class="h-100 bg-plum-plate bg-animation">
        <div
          style="
            position: absolute;
            width: 100%;
            height: 100%;
            background-size: cover;
            opacity: 0.3;
          "
          :style="{
            'background-image': 'url(/originals/writing.jpg)',
          }"
        ></div>
        <div class="d-flex h-100 justify-content-center align-items-center">
          <div
            class="
              app-login-box
              col-md-8
              d-flex
              align-items-center
              justify-content-center
              flex-column
            "
          >
            <div class="app-logo-inverse mb-3" :class="logoClass"></div>
            <div class="modal-dialog w-100 mx-auto">
              <div class="modal-content">
                <form @submit.prevent="validarEmail">
                  <div
                    class="
                      modal-body
                      flex-column
                      d-flex
                      justify-content-center
                      align-items-center
                    "
                  >
                    <div class="h5 modal-title text-center">
                      <h4 class="mt-2">
                        <div>Mis Recibos Digitales</div>
                        <span>Recuperar contraseña.</span>
                      </h4>
                    </div>
                    <div class="form-row col-12">
                      <float-label
                        label="Ingrese su CUIL:"
                        clase="col-12"
                        id="cuil"
                        placeholder="Ingrese su CUIL"
                        type="text"
                        inputmode="numeric"
                        classInput="form-control"
                        v-model="cuil"
                        :required="enviado && !cuil.required"
                        :numeric="enviado && !cuil.numeric"
                      >
                      </float-label>
                      <float-label
                        label="Ingrese su email:"
                        clase="col-12"
                        id="email"
                        placeholder="Ingrese su email"
                        type="email"
                        classInput="form-control"
                        v-model="email"
                        :required="enviado && !$v.email.required"
                      >
                      </float-label>
                    </div>
                  </div>
                  <div class="modal-footer clearfix">
                    <div class="float-right">
                      <button class="btn btn-primary btn-lg" type="submit">
                        Recuperar contraseña
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div class="text-center text-white opacity-8 mt-3">
              Copyright © PampaDev 2021
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { required, numeric } from "vuelidate/lib/validators";
import FloatLabel from "@/components/layouts/FloatLabel";
import axios from 'axios';
import Swal from 'sweetalert2'
export default {
  name: "Login",
  components: {
    FloatLabel,
  },
  data() {
    return {
      cuil: null,
      email: null,
      enviado: false,
    };
  },
  computed: {
    logoClass: () => {
      if (window.location.hostname == "recibos.pampadev.com.ar") {
        return ' desarrollo ';
      } else if (window.location.hostname == "hma.misrecibosdigitales.com.ar") {
        return ' ima ';
      } else if (
        window.location.hostname == "isnsl.misrecibosdigitales.com.ar"
      ) {
        return ' isnsl ';
      } else if (
        window.location.hostname == "diocesis.misrecibosdigitales.com.ar"
      ) {
        return " diocesis ";
      } else {
        return ' desarrollo ';
      }
    },
  },
  validations: {
    email: { required },
    cuil: { required, numeric },
  },
  mounted() {
    let title = document.querySelector("title");
    title.innerHTML = `Recuperar contraseña - MisRecibosDigitales`;
  },
  methods: {
    validarEmail() {
      console.log('validar email')
      axios
        .post("validar-email", { email: this.email, cuil: this.cuil })
        .then((res) => {
          if (res.data.success) {
              Swal.fire({
              icon: "success",
              title: "Atención",
              text: `Le hemos enviado un email para recuperar su contraseña.`,
            });
            this.$router.push({name:'login'})
          } else {
            Swal.fire({
              icon: "error",
              title: "Error",
              text: `Tus datos no se corresponden con los de ningún usuario`,
            });
          }
        })
        .catch((e) => {
          Swal.fire({
              icon: "error",
              title: "Error",
              text: `Ha ocurrido un error inesperado. ${e}`,
            });
        });
    },
  },
};
</script>